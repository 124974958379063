import {ApiService, GetListAPICommand, IAdapter} from '@simplifi/core/api';
import {environment} from '@simplifi/env/environment';
export class GetAllUsers<T> extends GetListAPICommand<T> {
  constructor(apiService: ApiService, adapter: IAdapter<T>, tenantId: string) {
    super(
      apiService,
      adapter,
      `${environment.baseApiUrl}${environment.tenantUserFacade}/tenants/${tenantId}/users/all`,
    );
  }
}
