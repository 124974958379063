export class UserDto {
  id!: string;
  name!: string;
  firstName!: string;
  email!: string;
  phone?: string;
  lastLogin?: string;
  createdOn?: string;
  defaultTenantName?: string;
  defaultTenantId?: string;
  roleId?: string;
  roleName?: string;
  isFoundationUser?: boolean;
  isSuperAdminUser?: boolean;
}

export class UserCount {
  count!: number;
}
